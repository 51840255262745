import React, { useState, useEffect } from 'react';
import {CloseOutlined, PlusOutlined, InfoCircleOutlined} from '@ant-design/icons'
import {Space, Flex, Divider, Form, Label,Typography, Input, Select, notification, Switch, Tooltip } from 'antd';
import {Button} from 'antd';
import { useNavigate } from 'react-router';
import ButtonCustom from '../../../../../components/Button'
import axios from 'axios'
const {Title} = Typography
const AddingForm = () => {
    const navigate = useNavigate()
    const [credentials, setCredentials] = useState({ 
        names: '', 
        nick: '', 
        password: "",
        passwordrepeat: "",
        active: 1,
        contacts: [
            [1, 1, 'pracownik@marshal.pl'],
            [2, 0, '123123123']
        ],
        roles: [1,2],
        apps: [1,2]
    });

    const [notPassable, setNotPassable] = useState(false)

    const [loading, setLoading] = useState(false)

    const [roles, setRoles] = useState(null)
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.post('/perms/get/roles');
            setRoles(response.data)
          } catch (error) {
            console.error('Errord fetchsing sdata:', error);
          }
        };
    
        fetchData();
    }, [])


    useEffect(() => {
        NicknameGenerator()
    },[credentials])

    const NicknameGenerator = () => {      
        const words = credentials.names.split(/\s+/).slice(0, 2);
        const polishToEnglishMap = {
            'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 
            'ó': 'o', 'ś': 's', 'ź': 'z', 'ż': 'z',
            'Ą': 'A', 'Ć': 'C', 'Ę': 'E', 'Ł': 'L', 'Ń': 'N', 
            'Ó': 'O', 'Ś': 'S', 'Ź': 'Z', 'Ż': 'Z'
        };
    
        const replacePolishChars = (word) => 
            word.split('').map(char => polishToEnglishMap[char] || char).join('');
    
        const cleanedWords = words.map(word => 
            replacePolishChars(word.toLowerCase()).replace(/[^a-z0-9]/gi, '')
        );
    
        // console.log(cleanedWords);
        setCredentials({
            ...credentials,
            nick: cleanedWords.join('.')
        });
        return true;
    };

    const [apps,setApps] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.post('/perms/get/apps');
            setApps(response.data)
          } catch (error) {
            console.error('Errord fetchsing sdata:', error);
          }
        };
    
        fetchData();
    }, [])

    const openErrorNotification = (data) => {
        notification.error({
          message: 'Nie można usunąć danego elementu',
          description: "Dany pracownik musi mieć przynajmniej po jednym typie danych kontaktowych.",
          duration: 3
        });
    };
    const openSuccessNotification = (data) => {
        notification.success({
          message: data.message,
          description: data.desc,
          duration: 3
        });
    };


    
    const onFinish = async () => {
        setLoading(true)
        try {
            const response = await axios.post('/user/add',{
                credentials: credentials
            });
            setLoading(false)
            if(response.data.status){
                openSuccessNotification({
                    message: "Pracownik dodany prawidłowo",
                    desc: "Za chwilę przekierujemy cię na zakładkę edycji danego pracownika"
                })
            }
            setTimeout(() => {
                navigate("/pracownicy/"+response.data.id)
            },3000)
        } catch (error) {
        console.error('Errord fetchsing sdata:', error);
        }
    }
    
    const onFinishFailed = () => {

    }

    const handleSwitchChange = (checked) => {
        const newActiveValue = checked ? 1 : 0;
        setCredentials({
          ...credentials,
          active: newActiveValue
        });
    };


    useEffect(() => {
          setNotPassable(!isValidPass());
      }, [credentials.password, credentials.passwordrepeat]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            [name]: value,
        }));
    };

    const addNewElement = () => {
        const updatedContacts = [...credentials.contacts];
        updatedContacts.push([
            !hasGivenSameType(1,3) ? 1 : 2,
            1,
            '']
            )
    
        setCredentials({
          ...credentials,
          contacts: updatedContacts
        });
    }

    const handleInputChangeContacts = (e, index, pos) => {
        const updatedContacts = [...credentials.contacts];
        updatedContacts[index][pos] = e;
        // console.log(pos,credentials.contacts[index][2])
        if(pos == 2 && credentials.contacts[index][0] == 1){
            setNotPassable(!isValidEmail(e))
        }
        if(pos == 2 && credentials.contacts[index][0] == 2){
            setNotPassable(!isValidPhone(e))
        }
        setCredentials({
          ...credentials,
          contacts: updatedContacts
        });
    };
    const handleInputChangeRoles = (e) => {  
        setCredentials({
          ...credentials,
          roles: e
        });
    };
    const handleInputChangeApps = (e) => {  
        setCredentials({
          ...credentials,
          apps: e
        });
    };

    const hasDuplicateType = (typeToCheck) => {
        const types = credentials.contacts.map((contact) => contact[0]);
        const occurrences = types.filter((type) => type === typeToCheck).length;
        return occurrences > 1;
    };
    
    const hasGivenSameType = (typeToCheck, count) => {
        const types = credentials.contacts.map((contact) => contact[0]);
        const occurrences = types.filter((type) => type === typeToCheck).length;
        return occurrences >= count
    };
    const isValidPass = () => {
        if(credentials.password == credentials.passwordrepeat){
            return true
        }
        return false
    }
    const isValidLength = (x) => {
        if(x.length >= 8){
            return true
        }
        return false
    }
    const isValidEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if (!emailRegex.test(email)) {
            return false; // Invalid email format
        }
        const domain = email.split('@')[1];
        if(domain !== 'marshal.pl' && domain !== 'kancelaria-kwiecien.pl'){
            return false;
        }
        return domain === 'marshal.pl' || domain === 'kancelaria-kwiecien.pl';
    };
    const isValidPhone = (phone) => {
        // Regular expression for basic email validation
        const phoneRegex = /^\d{9}$/;
        
        if (!phoneRegex.test(phone)) {
          return false; // Invalid email format
        }
        return true
    };

    const removeElement = (index) => {
        // Create a new array without the element at the specified index
        if(hasDuplicateType(credentials.contacts[index][0])){
            const updatedContacts = [...credentials.contacts.slice(0, index), ...credentials.contacts.slice(index + 1)];
            
            // Update the state with the new contacts array
            setCredentials({
              ...credentials,
              contacts: updatedContacts
            });
            return true;
        }
        openErrorNotification()
        return false;
    };

    return (
        <>{credentials ? <Form
            layout="vertical"
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
        <Flex gap={10} direction="vertical" justify={'space-between'} style={{padding: "20px 10px", width: "100%", marginBottom: "20px", background: "#FFF", boxShadow: "0px 0px 4px 1px #EFEFEF"}}>
            <Title style={{margin: 0}} level={4}>Nowy pracownik</Title>
            <Space>
                <Button onClick={()=> navigate("/pracownicy")}>Anuluj</Button>
                <Button disabled={notPassable} htmlType="submit">Zapisz</Button>
            </Space>
        </Flex>
        <Space className={['fullwidth', "mobileColLG"]} align="start" style={{gap: "20px", width: '100%'}}>
            <Space direction="vertical" style={{padding: "10px", width: "100%", background: "#FFF", boxShadow: "0px 0px 4px 1px #EFEFEF"}}>
                <Divider>Dane podstawowe</Divider>
                    <Form.Item label='Imię i nazwisko'>
                    <Input 
                        name='names' 
                        value={credentials.names}
                        onChange={handleInputChange}
                        placeholder='Imię i nazwisko' 
                        size='large' 
                        style={{width: "100%"}} 
                        required/>
                    </Form.Item>
                    <Form.Item label='Nazwa użytkownika'>
                    <Input 
                        name='nick' 
                        value={credentials.nick}
                        onChange={handleInputChange}
                        placeholder='Nazwa użytkownika' 
                        size='large' 
                        style={{width: "100%"}} 
                        required/>
                    </Form.Item>
                    <Form.Item label='Hasło' extra="Hasło musi składać się przynajmniej z 8 znaków.">
                    <Input.Password
                        name='password' 
                        type="password"
                        status={isValidLength(credentials.passwordrepeat) ? "" : "error"}
                        value={credentials.password}
                        onChange={handleInputChange}
                        placeholder='Hasło' 
                        size='large' 
                        style={{width: "100%"}}
                        required/>
                    </Form.Item>
                    <Form.Item label='Powtórz hasło'>
                    <Input.Password
                        name='passwordrepeat' 
                        type="password"
                        status={isValidPass() && isValidLength(credentials.passwordrepeat) ? "" : "error"}
                        value={credentials.passwordrepeat}
                        onChange={handleInputChange}
                        placeholder='Powtórz hasło' 
                        size='large' 
                        style={{width: "100%"}}
                        required/>
                    </Form.Item>
                    
                    {credentials.contacts.map((input, index) => (
                        <Space align='end' className={["contact"+index,"mobileColSM"]} style={{padding: "10px", background:"red", width: "100%", background: "#FFF", boxShadow: "0px 0px 4px 1px #EFEFEF"}}>
                            <Form.Item label='Typ kontaktu' style={{margin: 0}}>
                                <Select
                                    name={"type__"+index}
                                    defaultValue={input[0]}
                                    onChange={(e) => handleInputChangeContacts(e, index, 0)} 
                                    options={[{ value: 1, label: 'E-mail', disabled: hasGivenSameType(1,3) }, { value: 2, label: 'Numer telefonu', disabled: hasGivenSameType(2,3) }]}
                                    size="large" 
                                    style={{ width: 150, margin: '0' }}
                                    required
                                />
                            </Form.Item>
                            <Form.Item label='Dostęp do logowania' style={{margin: 0}}>
                                <Select
                                    name={"access__"+index}
                                    defaultValue={input[1]}
                                    onChange={(e) => handleInputChangeContacts(e, index, 1)}
                                    options={[{ value: 0, label: 'Nie' }, { value: 1, label: 'Tak' }]}
                                    size="large"
                                    style={{ width: 150, margin: '0' }}
                                    required
                                />
                            </Form.Item>

                            <Form.Item
                                label={input[0] == 1 ? 'E-mail' : "Numer telefonu"} 
                                style={{margin: 0, width: '100%'}}>
                                <Input   
                                    suffix={
                                        input[0] == 1 ? (isValidEmail(input[2]) ? "" : 
                                        <Tooltip title="Wpisz adres e-mail zgodny z domeną aplikacji">
                                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                        </Tooltip>
                                        ) : (isValidPhone(input[2]) ? "" : 
                                        <Tooltip title="Wpisz numer telefonu zgodny z formatem XXXXXXXXX (9 cyfr, bez dodatkowych znaków)">
                                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                        </Tooltip>
                                        )

                                    }                  
                                    status={ input[0] == 1 ? (isValidEmail(input[2]) ? "" : "error") : (isValidPhone(input[2]) ? "" : "error")}               
                                    name={"value__"+index} 
                                    onChange={(e) => handleInputChangeContacts(e.target.value, index, 2)}
                                    value={input[2]}
                                    placeholder='Wartość' 
                                    size='large' 
                                    style={{width: "100%", margin: '0'}} 
                                    required/>
                            </Form.Item>
                            <Button disabled={!hasDuplicateType(credentials.contacts[index][0])} size="large" onClick={() => removeElement(index)} icon={<CloseOutlined/>}></Button>
                        </Space>
                    ))}

            <Button disabled={(hasGivenSameType(1,3) && hasGivenSameType(2,3))} size="large" onClick={() => addNewElement()} icon={<PlusOutlined/>}></Button>

            </Space >
            <Space className='fullwidth' direction="vertical" style={{padding: "10px", background:"red", width: "100%", background: "#FFF", boxShadow: "0px 0px 4px 1px #EFEFEF"}}>
                <Divider>Uprawnienia i bezpieczeństwo</Divider>
                <Form.Item label='Role użytkownika'>
                    <Select
                        mode="multiple"
                        loading={true}
                        name={"roles"}
                        defaultValue={credentials.roles} 
                        options={roles}
                        size="large" 
                        onChange={(e) => handleInputChangeRoles(e)}
                        style={{ width: '100%', margin: '0' }}
                    />
                </Form.Item>
                <Form.Item label='Dostęp do aplikacji'>
                    <Select
                        mode="multiple"
                        name={"apps"}
                        defaultValue={credentials.apps} 
                        onChange={(e) => handleInputChangeApps(e)}
                        options={apps}
                        size="large" 
                        style={{ width: '100%', margin: '0' }}
                    />
                </Form.Item>
                <Form.Item label='Aktywacja użytkownika'>
                    <Switch name="active" onChange={handleSwitchChange} checked={credentials.active} />
                </Form.Item>
            </Space >
        </Space>
        </Form> : <></> }</>
    );
};
export default AddingForm;